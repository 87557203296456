import {
  ButtonGroup,
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Message,
  MessageBox,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Menu,
  MenuItem,
  Submenu,
  Badge,
  Tooltip,
  Scrollbar,
  Card,
  Select,
  Option,
  OptionGroup,
  Table,
  TableColumn,
  Loading,
  Switch,
  Icon,
  Pagination,
  Tag,
  DatePicker,
  Transfer,
  Tree,
  Checkbox,
  CheckboxGroup,
  Upload,
  Tabs,
  TabPane,
  Notification,
  Radio,
  RadioGroup,
  TimeSelect,
  TimePicker,
  Divider,
  Popover,
  Link,
  Step,
  Steps,
  Drawer,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  Progress,
  Carousel,
  CarouselItem,
} from 'element-ui';

const setupElement = {
  install: (Vue) => {
    Vue.use(Button)
      .use(ButtonGroup)
      .use(Form)
      .use(FormItem)
      .use(Input)
      .use(InputNumber)
      .use(Row)
      .use(Col)
      .use(Breadcrumb)
      .use(BreadcrumbItem)
      .use(Dropdown)
      .use(DropdownMenu)
      .use(DropdownItem)
      .use(Dialog)
      .use(Dropdown)
      .use(Menu)
      .use(MenuItem)
      .use(Submenu)
      .use(Badge)
      .use(Tooltip)
      .use(Scrollbar)
      .use(Card)
      .use(Select)
      .use(Option)
      .use(OptionGroup)
      .use(Table)
      .use(TableColumn)
      .use(Loading.directive)
      .use(Switch)
      .use(Icon)
      .use(Pagination)
      .use(Tag)
      .use(DatePicker)
      .use(Transfer)
      .use(Tree)
      .use(Checkbox)
      .use(CheckboxGroup)
      .use(Upload)
      .use(Tabs)
      .use(TabPane)
      .use(Radio)
      .use(RadioGroup)
      .use(TimeSelect)
      .use(TimePicker)
      .use(Divider)
      .use(Popover)
      .use(Link)
      .use(Step)
      .use(Steps)
      .use(Drawer)
      .use(Collapse)
      .use(Timeline)
      .use(TimelineItem)
      .use(CollapseItem)
      .use(Carousel)
      .use(CarouselItem)
      .use(Progress);
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.prototype.$message = Message;
  },
};
export default setupElement;
